<template>
  <div class="wrapper">
    <div class="invisibile-options">
      <span v-for="option in options" :key="option[0]">{{option[1]}}</span>
    </div>
    <div class="selection" :class="{ right: toggleRight }" @click="toggle">{{selectedLabel}}</div>
  </div>
</template>

<script>
export default {
  name: 'Radio',
  computed: {
    selectedValue() { return this.options[this.selectedIndex][0] },
    selectedLabel() { return this.options[this.selectedIndex][1] },
    toggleRight() { return this.selectedIndex === 1 }
  },
  data() {
    return {
      selectedIndex: 0
    }
  },
  methods: {
    toggle() {
      this.selectedIndex = Math.abs(this.selectedIndex - 1)
      console.log('emit input', this.selectedValue)
      this.$emit('input', this.selectedValue)
    }
  },
  props: [
    'options',
    'value'
  ]
}
</script>

<style scoped>
  .wrapper {
    text-align: left;
    border-radius: .25rem;
    background-color: var(--white);
    padding: .25rem;
    display: inline-block;
    position: relative;
  }

  .invisibile-options {
    color: transparent;
  }

  .invisibile-options, .selection {
    font-size: .75rem;
    font-weight: 400;
    padding: .25rem;
  }

  .selection {
    display: inline-block;
    background-color: var(--green);
    border-radius: .25rem;
    margin: 0 .25rem;
    cursor: pointer;
    position: absolute;
    top: .25rem;
    left: 0;
    transition: left .25s, transform .25s;
  }

  .selection.right {
    left: 100%;
    transform: translateX(-100%) translateX(-.5rem);
  }
</style>
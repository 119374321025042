<template>
  <span v-if="shouldShow" @click="goHome">&#8592;</span>
</template>

<script>
export default {
  name: 'HomeButton',
  props: ['component'],
  computed: {
    shouldShow() {
      return this.component && this.component.type.name !== 'Players'
    }
  },
  methods: {
    goHome() {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>
  span {
        position: absolute;
      top: .75rem;
      left: 1rem;
      z-index: 1000;
      padding: .5rem;
      font-size: 1.5rem;
      line-height: 1rem;
      opacity: .5;
      color: var(--black);
      cursor: pointer;
  }
</style>
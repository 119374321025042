<template>
  <div>
    <NewPlayerForm />
    <PlayerList /> 
    <router-link class="play-link blob" to="/play">
      <span>Play</span>
    </router-link>
  </div>
</template>

<script>
import NewPlayerForm from './NewPlayerForm.vue'
import PlayerList from './PlayerList.vue'

export default {
  name: 'Players',
  components: {
    NewPlayerForm,
    PlayerList
  }
}
</script>

<style scoped>
  .play-link {
    display: block;
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    height: 5rem;
    width: 5rem;
    background-color: var(--green);
    text-align: center;
  }

  .play-link > span {
    color: var(--white);
    font-weight: 600;
    text-decoration: none;
  }
</style>
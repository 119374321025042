import { createStore } from 'vuex'
import mutations from './mutations.js'
import actions from './actions.js'

const store = createStore({
  state () {
    return {
      players: JSON.parse(localStorage.getItem('players')) || [],
      playerBench: [],
      questions: null,
      intensity: 1
    }
  },
  actions,
  mutations
})

export default store
<template>
  <div class="card" :class="{ even: even, deleteOpen: deleteOpen }" @click="toggleDeleteButton">
    <div class="topCard">
      <div class="name">
        <h4>{{player.name}}</h4>
        <span class="identity">{{prettyIdentity}}</span>
      </div>
      <div class="preferences-wrapper">
        <div class="preference"
            :class="{ woman: preference == 'Women' }"
            v-for="preference in sortedPreferences"
            v-bind:key="preference">

            <span>{{preference}}</span>
        </div>
      </div>
    </div>
    <button class="delete" @click="deletePlayer">Delete</button>
  </div>
</template>

<script>
export default {
  name: 'PlayerCard',
  props: [
    'player',
    'even',
    'index'
  ],
  computed: {
    prettyIdentity() {
      return this.player.identity === 'man' ? 'Man' : 'Woman'
    },
    sortedPreferences() {
      return Array.from(this.player.preferences).sort().map(gender => {
        return gender === 'man' ? 'Men' : 'Women'
      })
    }
  },
  data() {
    return {
      deleteOpen: false
    }
  },
  methods: {
    deletePlayer() {
      this.$store.dispatch('deleteAtIndex', this.index)
    },
    toggleDeleteButton() {
      this.deleteOpen = !this.deleteOpen
    }
  }
}
</script>

<style scoped>
  .card {
    position: relative;
  }

  .topCard {
    background-color: var(--yellow);
    color: var(--white);
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    transition: transform .125s;
  }

  .card.even .topCard {
    background-color: var(--green);
  }

  .name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem 1rem 1rem 2rem;
    max-width: 80%;
  }

  h4 {
    margin: 0;
    font-size: 1.5rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .preferences-wrapper {
    min-height: 5rem;
  }

  .preference {
    display: inline-block;
    height: 100%;
    width: 1.5rem;
    position: relative;
    background-color: var(--red);
  }

  .preference.woman {
    background-color: var(--pink);
  }

  .preference span {
    transform: translateX(-50%) translateY(-50%) rotateZ(270deg);
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
  }

  .deleteOpen .topCard {
    transform: translateX(-5rem);
  }

  .delete {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 5rem;
    background-color: var(--red);
    border: none;
    color: var(--white);
    font-weight: 600;
    box-shadow: inset 3px 0px 8px -5px var(--black);
  }
</style>
<template>
  <div class="wrapper" :class="intensityClass">
    <transition name="fade">
      <div class="options" 
           v-if="player"
           :class="{ showQuestion: question !== null }"
           @click="doneWithQuestion">

        <div v-if="!question" class="truth" @click="play('truth', $event)">
          <span>Truth</span>
        </div>
        <div class="mainMessage">
          <span v-if="!question">{{player.name}}</span>
          <span v-if="question">{{questionType}}</span>
        </div>
        <div v-if="question" class="question">{{question}}</div>
        <div class="dare" v-if="!question" @click="play('dare', $event)">
          <span>Dare</span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { shuffle } from '../lib/util.js'

function fillQuestionActors(question, mainPlayer, allPlayers) {
  // We're going to need to iterate through the question and see if we can fill in all of the actors.
  // This is computationally expensive, but hopefully the lists are small

  const otherPlayers = shuffle(allPlayers.filter(player => {
    return mainPlayer.name !== player.name
  }))
  
  for(const mainActorId in question.actors) {
    const mainActor = question.actors[mainActorId]

    // The mainPlayer must be an identitythat fits for this actor
    if (mainActor.identity.indexOf(mainPlayer.identity) < 0) { continue }

    const filledQuestion = recursivelyFillActors({
      question: question.question,
      actors: {
        [mainActorId]: mainPlayer
      }
    }, question, otherPlayers)

    if (filledQuestion) { return filledQuestion }
  }
}

function mergeQuestionText(question) {
  let text = question.question
  Object.keys(question.actors).forEach(id => {
    text = text.replaceAll(`$${id}`, question.actors[id].name)
  })

  return text
}

function recursivelyFillActors(filledQuestion, question, remainingPlayers) {
  remainingPlayers = Array.from(remainingPlayers)
  
  const actorToFill = Object.keys(question.actors).filter(id => {
    return filledQuestion.actors[id] === undefined
  }).sort()[0]

  if (actorToFill === undefined) {
    if (testQuestionActorAssignments(filledQuestion, question)) {
      return filledQuestion
    } else {
      return false
    }
  }
  
  for (let i=0,max=remainingPlayers.length; i<max; i++) {
    filledQuestion.actors[actorToFill] = remainingPlayers.splice(i,1)[0]
    let finalFill
    if ((finalFill = recursivelyFillActors(filledQuestion, question, remainingPlayers))) {
      return finalFill
    } else {
      remainingPlayers.splice(i,0,filledQuestion.actors[actorToFill])
      delete filledQuestion.actors[actorToFill]
    }
  }

  return null
}

function testQuestionActorAssignments(filledQuestion, question) {
  for (const actorId in question.actors) {
    const filledActor = filledQuestion.actors[actorId]
    const questionActor = question.actors[actorId]

    if (questionActor.identity.indexOf(filledActor.identity) < 0) { return false }
    for (let preference of questionActor.preferences) {
      // $ means the preference is for another player
      if (preference[0] === "$") {
        preference = filledQuestion.actors[preference.substr(1)].identity
      }

      if (filledActor.preferences.indexOf(preference) < 0) { return false }
    }
  }

  return true
}

export default {
  name: 'Choose',
  computed: {
    ...mapState({
      intensityClass(state) {
        return `intensity-${state.intensity}`
      },
      players: 'players',
      questions: 'questions'
    })
  },
  data() {
    return {
      player: null,
      question: null,
      questionType: null
    }
  },
  methods: {
    getQuestion(mainPlayer, type, allPlayers) {
      this.questionType = type
      const questions = mainPlayer.questions[type]
      const questionIndex = questions[Math.floor(Math.random() * questions.length)]
      const question = this.questions[questionIndex]

      return fillQuestionActors(question, mainPlayer, allPlayers)
    },
    play (type, $event) {
      $event.preventDefault()
      $event.stopPropagation()
      const question = this.getQuestion(this.player, type, this.players)
      this.question = question ? mergeQuestionText(question) : "No options found! Try again :("
    },

    doneWithQuestion () {
      if (this.question) {
        this.$router.push('/play')
      }
    }
  },
  mounted() {
    this.$store.dispatch('choosePlayer').then(player => {
      this.player = player
    })
  }
}
</script>

<style scoped>
  .wrapper {
    height: 100%;
    width: 100%;
    background-color: var(--red);
  }

  .options {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .options .truth,
  .options .dare {
    flex: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white);
    font-size: 3rem;
    font-weight: 600;
    cursor: pointer;
  }

  .mainMessage {
    transform: scale(2, 2) rotateZ(-10deg);
    width: 100%;
    font-size: 1rem;
    color: var(--white);
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    padding: .5rem 0;
    border-top: .25rem var(--white) solid;
    border-bottom: .25rem var(--white) solid;
    box-shadow: 0 0 10px -2px var(--black);
    background-color: var(--blue);
  }

  .showQuestion .mainMessage {
    margin-top: 5rem;
  }

  .showQuestion.options {
    justify-content: space-evenly;
  }

  .question {
    font-size: 3rem;
    font-weight: 600;
    padding: .5rem;
    text-align: center;
    color: var(--white);
  }

  .wrapper.intensity-5 {
    background-color: var(--green);
  }

  .fade-enter-active {
    transition: opacity .125s;
  }

  .fade-enter-from {
    opacity: 0;
  }

  .fade-enter-to {
    opacity: 1;
  }
</style>
export default {
  addPlayer (state, payload) {
    state.players.push(payload)
    state.playerBench = []
  },

  allocateQuestions(state, allocations) {
    console.log("TODO: allocate questions mutation", allocations)
    allocations.forEach((allocation, index) => {
      state.players[index].questions = allocation
    })
  },

  deleteAtIndex (state, index) {
    state.players.splice(index, 1)
    state.playerBench = []
  },

  updateBench (state, bench) {
    state.playerBench = bench
  },

  setIntensity (state, intensity) {
    state.intensity = intensity
  },

  setQuestions (state, questions) {
    state.questions = questions
  }
}
const VueRouter = require('vue-router')
import { createApp } from 'vue'
import Root from './Root.vue'
import store from './store/index.js'
import Players from './components/Players.vue'
import Play from './components/Play.vue'
import Choose from './components/Choose.vue'

const app = createApp(Root)

const routes = [
  { path: '/', component: Players },
  { path: '/play', component: Play },
  { path: '/choose', component: Choose }
]

const router = VueRouter.createRouter({
  history: VueRouter.createWebHashHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.path === '/choose' && from.path !== '/play') {
    return next({ path: '/play'})
  }

  next()
})

app.use(store)
app.use(router)
app.mount('#app')

store.dispatch('fetchQuestions').then(() => {
  store.dispatch('allocateQuestions')
})

<template>
  <div class="wrapper" :class="[backgroundColor, blockTransitions ? 'blockTransitions' : '']">
    {{ this.question }}
    <router-link to="/choose" class="play blob" v-if="readyToPlay"><span>Go!</span></router-link>
    <div class="intensity-select">
      <IntensitySelect />
    </div>
  </div>
</template>

<script>
import IntensitySelect from './IntensitySelect.vue'
import { mapState } from 'vuex'

export default {
  name: 'Game',
  beforeRouteLeave(to) {
    if (to.path !== '/choose') {
      this.blockTransitions = true
    }
  },
  components: {
    IntensitySelect
  },
  data() {
    return {
      question: null,
      blockTransitions: false
    }
  },
  computed: {
    readyToPlay () {
      return this.$store.state.players.filter(player => {
        // If we haven't allocated questions yet, block playing
        if (player.questions === undefined) { return true }
        return player.questions.truth.length === 0 && player.questions.dare.length === 0
      }).length === 0
    },
    ...mapState({
      players: 'players',
      backgroundColor(state) {
        return `intensity-${state.intensity}`
      },
    })
  }
}
</script>

<style scoped>
  .blockTransitions,
  .blockTransitions * {
    transition: none !important;
  }

  .wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    transition: background-color .125s;
    overflow: hidden;
  }

  .wrapper.intensity-1 {
    background-color: var(--green);
  }

  .wrapper.intensity-2 {
    background-color: var(--blue);
  }

  .wrapper.intensity-3 {
    background-color: var(--yellow);
  }

  .wrapper.intensity-4 {
    background-color: var(--pink);
  }

  .wrapper.intensity-5 {
    background-color: var(--red);
  }

  .wrapper.wrapper.intensity-5 .play {
    background-color: var(--green);
  }

  .play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) scale(1,1);
    height: 10rem;
    width: 10rem;
    background-color: var(--red);
    border: none;
    color: var(--white);
    font-size: 3rem;
    cursor: pointer;
    transition: background-color .125s;
  }

  .play > span {
    transition: opacity .125s;
  }

  .intensity-select {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .navigate-leave-active {
    transition: opacity .75s; /* This doesn't do anything, just hooks into vue's transition duration */
  }

  .wrapper.navigate-leave-active .play {
    transition: transform .75s;
  }

  .wrapper.navigate-leave-from .play {
    transform: translateX(-50%) translateY(-50%);
  }

  .wrapper.navigate-leave-to .play {
    transform: translateX(-50%) translateY(-50%) scale(7,7);
  }

  .wrapper.navigate-leave-active .play > span,
  .wrapper.navigate-leave-active .intensity-select {
    transition: opacity .125s;
  }

  .wrapper.navigate-leave-to .play  > span,
  .wrapper.navigate-leave-to .intensity-select {
    opacity: 0;
  }

  .wrapper.navigate-leave-from .play > span,
  .wrapper.navigate-leave-from .intensity-select {
    opacity: 1
  }
</style>
export default {
  allocateQuestions (players, questions) {
    /*
     * We are going to apply a couple simple filters to weed out questions
     * that are obviously imcompatible with a given player
     * 
     * When the question actually executes we will do a more thorough scan to look
     * at specific player combinations, but for now let's just make the list smaller
    */
    questions = Array.from(questions)
    
    // Filter out questions where there are not enough players
    questions = questions.filter(question => {
      return Object.keys(question.actors).length <= players.length
    })

    return players.map((player) => {
      let questionsMap = {
        truth: [],
        dare: []
      }

      // Filter out questions where none of the actors match the player's identity
      questions.forEach((question, index) => {
        for (const id in question.actors) {
          const actor = question.actors[id]
          if (actor.identity.indexOf(player.identity) >= 0) {
            questionsMap[question.type].push(index)
            break
          }
        }
      })

      return questionsMap
    })
  }
}
<template>
  <div class="frame">
    <div class="mobile-slot">
      <router-view v-slot="{ Component }">
        <HomeButton :component="Component" />
        <transition name="navigate" mode="out-in">
          <component :is="Component"></component>
        </transition>
      </router-view>
    </div>
    <div class="desktop-hello">
      <h1>TrueDare</h1>
      <h2>Truth? Or Dare? With whomever you choose.</h2>
    </div>
  </div>
</template>

<script>
import HomeButton from './HomeButton.vue'

export default {
  name: 'Frame',
  components: {
    HomeButton
  }
}
</script>

<style scoped>
  .frame,
  .mobile-slot {
    height: 100%;
    width: 100%;
  }

  .desktop-hello {
    display: none;
    color: var(--black);
    max-width: 30rem;
  }
  
  .desktop-hello h1 {
    font-size: 3rem;
  }

  .desktop-hello h2 {
    font-size: 2rem;
  }

  .frame {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .mobile-slot {
    position: relative;
  }

  @media only screen and (min-width: 700px) {
    .mobile-slot {
      width: 400px;
      height: 800px;
      max-height: 100%;
      border-radius: 1rem;
      overflow: hidden;
      box-shadow: 0 0 1rem 0 var(--black);
      margin: 1rem;
    }

    .desktop-hello {
      display: block;
    }
  }
</style>

import playerUtils from '../lib/player.js'
import questionUtils from '../lib/question.js'

export default {
  allocateQuestions({ commit, state }) {
    commit('allocateQuestions', playerUtils.allocateQuestions(state.players, state.questions))
  },

  addPlayer ({ commit, dispatch }, payload) {
    commit('addPlayer', payload)
    dispatch('persistPlayers')
  },

  choosePlayer ({ state, commit }) {
    let playerBench = state.playerBench.length > 0 ? state.playerBench : Array.from(state.players)
    const playerIndex = Math.floor(Math.random() * playerBench.length)
    const player = playerBench.splice(playerIndex, 1)[0]

    commit('updateBench', playerBench)
    return player
  },

  deleteAtIndex ({ commit, dispatch }, index) {
    commit('deleteAtIndex', index)
    dispatch('persistPlayers')
  },

  async fetchQuestions({ commit } ) {
    return fetch('/questions.json').then((resp) => {
      return resp.json()
    }).then((questions) => {
      commit('setQuestions', questions.map(questionUtils.enrichQuestion))
    })
  },

  persistPlayers ({ state }) {
    localStorage.setItem('players', JSON.stringify(state.players.map(player => {
      return {
        name: player.name,
        identity: player.identity,
        preferences: player.preferences
      }
    })))  
  },
  
  setIntensity ({ commit }, intensity) {
    commit('setIntensity', intensity)
  }
}
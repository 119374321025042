<template>
  <Frame />
</template>

<script>
import Frame from './components/Frame.vue'

export default {
  name: 'App',
  components: {
    Frame
  }
}
</script>

<style>
:root {
  --red: #EE6352;
  --green: #59CD90;
  --blue: #3FA7D6;
  --yellow: #FAC05E;
  --pink: #F79D84;
  --black: #071922;
  --grey: #A6B5BA;
  --gray: #A6B5BA;;
  --white: #E5EDF1;
}

html, body {
  background-color: var(--blue);
  margin: 0;
  height: 100%;
  width: 100%;
  overscroll-behavior: none;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

* {
  box-sizing: border-box;
}

input {
  border-radius: 4px;
  border: none;
  font-size: 16px;
  padding: 8px;
  background-color: var(--white);
  outline: none;
}

input::placeholder {
  color: var(--gray);
  opacity: 1;
}

.blob {
  box-shadow: 0 0 5px var(--black);
  border-radius: 56% 72% 98% 61%;
  animation: infinite blob 13s linear;
  box-shadow: 1px 1px 6px -2px var(--black);
  position: relative;

}

.blob > span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

@keyframes blob {
  0% {
    border-radius: 63% 34% 14% 51% / 28% 72% 40% 71%;
  }

  10% {
    border-radius: 63% 34% 69% 51% / 84% 72% 21% 36%;
  }

  20% {
    border-radius: 63% 87% 69% 51% / 18% 72% 81% 51%;
  }

  30% {
    border-radius: 98% 30% 69% 51% / 100% 72% 31% 51%;
  }

  40% {
    border-radius: 26% 72% 69% 51% / 52% 72% 81% 100%;
  }

  50% {
    border-radius: 76% 28% 69% 91% / 50% 100% 81% 100%;
  }

  60% {
    border-radius: 42% 90% 17% 71% / 100% 100% 100% 100%;
  }

  70% {
    border-radius: 42% 90% 100% 71% / 100% 51% 100% 38%;
  }

  80% {
    border-radius: 89% 90% 31% 71% / 100% 51% 100% 38%;
  }

  90% {
    border-radius: 89% 24% 19% 71% / 35% 51% 30% 38%;
  }

  100% {
    border-radius: 63% 34% 14% 51% / 28% 72% 40% 71%;
  }
}
</style>

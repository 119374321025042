<template>
  <div class="wrapper">
    <span v-for="index in [1,2,3,4,5]"
          v-bind:key="index"
          :class="{off: index > intensity}"
          @click="setIntensity(index)">
      🔥
    </span>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'IntensitySelect',
  computed: mapState({
    intensity: 'intensity'
  }),
  methods: {
    setIntensity(intensity) {
      this.$store.dispatch('setIntensity', intensity)
    }
  }
}
</script>

<style scoped>
  .wrapper {
    display: flex;
    justify-content: space-around;
    padding: 1rem;
  }

  span {
    font-size: 3rem;
    cursor: pointer;
  }

  span.off {
    filter: grayscale(100%);
  }
</style>

<template>
<div>
  <PlayerCard v-for="(player, index) in players" 
              :player="player"
              :key="player.name"
              :index="index"
              :even="index % 2 === 0" />
</div>
</template>

<script>
import { mapState } from 'vuex'
import PlayerCard from './PlayerCard.vue'
export default {
  name: 'PlayerForm',
  components: {
    PlayerCard
  },
  computed: mapState({
    players: 'players'
  }),
  methods: {
    deletePlayer (index) {
      this.$store.dispatch('deleteAtIndex', index)
    }
  }
}
</script>

<style scoped>
.player {
  margin: 16px;
}
</style>
<template>
  <div class="wrapper">
    <span class="option"
          :class="{ active: value.indexOf(option[0]) >= 0 }" 
          v-for="option in options" 
          v-bind:key="option[0]"
          @click="toggle(option[0])">

      {{option[1]}}
    </span>
  </div>
</template>

<script>
export default {
  name: 'Selectable',
  methods: {
    toggle(option) {
      const index = this.value.indexOf(option)
      let newValue = Array.from(this.value)
      if (index === -1) {
        newValue.push(option)
      } else {
        newValue.splice(index, 1)
      }
      this.$emit('input', newValue)
    }
  },
  props: [
    'options',
    'value'
  ]
}
</script>

<style scoped>
  .option {
    display: inline-block;
    background-color: var(--white);
    color: var(--black);
    border-radius: .25rem;
    margin-right: 1.5rem;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 400;
    padding: .25rem .5rem;
    transition: background-color .25s, color .25s;
  }

  .option.active {
    background-color: var(--green);
    color: var(--white);
  }

  .option:last-child {
    margin: 0;
  }
</style>
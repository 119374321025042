<template>
  <div class="new-player-form">
    <input class="name" type="text" v-model="name" placeholder="Name" />
    <div class="sub-options">
      <label>
        <h4>Gender</h4>
        <Radio :options="this.identityOptions" v-bind:value="identity" v-on:input="identity = $event" />
      </label>
      <label>
        <h4>Plays with</h4>
        <Selectable :options="this.preferenceOptions" v-bind:value="this.preferences" v-on:input="preferences = $event" />
      </label>
    </div>
    <button @click="create">Add Player</button>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Radio from './Radio.vue'
import Selectable from './Selectable.vue'

export default {
  name: 'NewPlayerForm',
  components: {
    Radio,
    Selectable
  },
  computed: mapState({
    existingNames: state => {
      return state.players.map(player => player.name)
    }
  }),
  data: () => {
    return {
      name: '',
      identity: 'man',
      identityOptions: [
        ['man', 'Man'],
        ['woman', 'Woman']
      ],
      preferences: ['man', 'woman'],
      preferenceOptions: [
        ['man', 'Men'],
        ['woman', 'Women']
      ]
    }
  },
  methods: {
    create: function() {
      if (this.existingNames.indexOf(this.name) >= 0) {
        alert("Someone with that name already exists!")
        return
      }

      this.$store.dispatch('addPlayer', {
        name: this.name,
        identity: this.identity,
        preferences: this.preferences
      })
      
      this.name = ""
    }
  }
}
</script>


<style scoped>
.new-player-form {
  padding: 1rem;
  margin-bottom: 2rem;
}

.name {
  width: 100%;
}

.sub-options {
  display: flex;
  margin: .5rem 0 1.5rem 0;
  justify-content: space-between;
}

label {
  font-size: 1.5rem;
  color: var(--white);
  text-align: center;
  font-weight: bold;
}

label > h4 {
  margin: 0;
}

button {
  width: 100%;
  background-color: var(--green);
  border: none;
  color: var(--white);
  border-radius: .25rem;
  font-weight: 600;
  font-size: 1rem;
  padding: .5rem;
  outline: none;
  cursor: pointer;
}
</style>
